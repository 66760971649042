<template>
  <div>
    <setup-table
      :items="items"
      :headers="headers"
      :loading="loading"
      :clearItem="!showForm || !editedItem.id"
      hideCreate
      hideRowSelect
      hide-menu
      flip-table
      showSearch
      @editItem="editItem"
      pagination
    >
      <template #itemForm>
        <div class="d-flex align-center col col-12">
          <p class="text-h5">Student Roster</p>
        </div>
        <v-row>
          <v-col cols="12" md="12">
            <v-card>
              <div class="pt-4 px-4">
                <v-select
                  label="School"
                  :value="schoolLocations[schoolId.name]"
                  v-model="schoolId"
                  :items="schoolLocations"
                  item-text="name"
                  item-value="id"
                  outlined
                  class="mb-0"
                  v-on:input="getItems(schoolId)"
                />
              </div>
            </v-card>
          </v-col>
        </v-row>
      </template>
    </setup-table>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { getStudentRoster, saveStudent } from '@/store/modules/Roster/actions';
import { GET_LOCATIONS } from '@/store/modules/Location/actions';
import SetupTable from './SetupTable.vue';

export default {
  name: 'RosterTable',
  inject: ['eventHub'],
  components: { SetupTable },
  data() {
    return {
      showForm: false,
      items: [],
      editedItem: {},
      loading: false,
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Student ID', value: 'studentId' },
        { text: 'Grade', value: 'grade' },
        { text: 'Sex', value: 'sex' },
      ],
      schoolId: 1,
      schoolLocations: [],
    };
  },
  computed: {
    ...mapGetters('roster', ['studentRoster']),
    ...mapGetters('location', ['schools']),
  },
  async mounted() {
    await this.getItems();
    await this.getLocations();
    this.schoolLocations = this.schools;
  },
  methods: {
    ...mapActions('roster', [getStudentRoster, saveStudent]),
    ...mapActions('location', [GET_LOCATIONS]),
    async getItems() {
      this.loading = true;
      if (!this.schools.length) {
        return (this.loading = false);
      }
      await this.getStudentRoster({ location: this.schoolId });
      this.items = this.studentRoster;
      this.loading = false;
    },
    createItem() {
      this.showForm = true;
      this.editedItem = { id: 0 };
    },
    editItem(item) {
      this.editedItem = { ...item };
      this.showForm = true;
    },
    async saveItem(item) {
      try {
        const response = await this.saveStudent(item);
        if (response && response.done) {
          this.$myalert.success('Student Info saved');
          this.showForm = false;
          await this.getItems();
          this.editedItem = { ...item, id: response.id };
        }
      } catch (error) {
        this.$myalert.error(error);
      }
    },
    closeForm() {
      this.showForm = false;
    },
  },
};
</script>
